<template>
  <component :is="tag" class="average-city-prices-section">
    <h3 v-html="$t('t_AVERAGECITYPRICES')" />
    <div class="average-city-prices-list">
      <destination-card
        v-for="(city, idx) in averageCityPricesList"
        :key="city.id"
        v-bind="getAverageCityPriceData(city)"
        v-show="idx < maxNumCards || expanded"
      />
    </div>
    <wds-button-link
      v-if="averageCityPricesList.length > maxNumCards"
      target="_blank"
      rel="noopener"
      class="average-city-prices-more-link"
      :text="$t(expanded ? 't_SHOWLESS' : 't_SHOWMORE')"
      :icon-end="expanded ? 'chevron-up' : 'chevron-down'"
      @click="toggleExpanded()"
      @keyup.enter="toggleExpanded()"
    />
  </component>
</template>

<script>
import { WdsButtonLink } from '@wds/components';
import DestinationCard from '~/components/common/DestinationCard';
import { DEFAULT_CURRENCY } from '~/config/currencies';
import { UrlBuilder } from '~/lib/url';

const MAX_NUM_CARDS = 9;

export default {
  name: 'AverageCityPrices',
  components: {
    DestinationCard,
    WdsButtonLink,
  },
  props: {
    tag: {
      type: String,
      required: false,
      default: 'div',
    },
    propertyTypeObj: {
      type: Object,
      required: true,
    },
    averageCityPricesList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    maxNumCards() {
      return MAX_NUM_CARDS;
    },
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },
    getAverageCityPriceData(city) {
      const cityImage = city?.image?.small || city?.image?.mobile || null;
      const cityImageUrl = cityImage ? new UrlBuilder(cityImage).addProtocol() : null;

      const locationData = {
        propertyTypeSlug: this.$t(this.propertyTypeObj.slug),
        urlFriendlyContinent: city.urlFriendlyContinent,
        urlFriendlyCountry: city.urlFriendlyCountry,
        urlFriendlyCity: city.urlFriendlyName,
      };

      const avgCitySharedMinPrice = city?.avgDormPrice?.value || 0;
      const avgCityPrivateMinPrice = city?.avgPrivatePrice?.value || 0;
      const avgCityPriceCurrency =
        city?.avgDormPrice?.currency || city?.avgPrivatePrice?.currency || DEFAULT_CURRENCY.code;
      const avgCityPrices = [parseFloat(avgCitySharedMinPrice), parseFloat(avgCityPrivateMinPrice)].filter(
        (price) => price > 0
      );

      return {
        name: city.name,
        image: cityImageUrl,
        location: city?.country || null,
        price: {
          value: Math.min(...avgCityPrices),
          currency: avgCityPriceCurrency,
        },
        link: this.$url.getCityUrl(locationData),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.average-city-prices-section {
  margin-top: wds-rem(120px);
  margin-bottom: wds-rem(120px);
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  gap: $wds-spacing-l;
  padding: 0 $wds-spacing-m;

  h3 {
    @include title-3-bld;
    color: $wds-color-ink-darker;

    ::v-deep span {
      color: $wds-color-purple;
    }
  }

  .average-city-prices-list {
    display: flex;
    flex-direction: column;
    gap: $wds-spacing-m;
  }

  .average-city-prices-more-link {
    align-self: flex-end;
    justify-self: flex-end;
  }

  @include desktop {
    margin-top: wds-rem(200px);
    margin-bottom: wds-rem(200px);
    gap: wds-rem(56px);
    padding: 0 wds-rem(40px);

    h3 {
      @include title-1-bld;
    }

    .average-city-prices-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: $wds-spacing-l;
      width: 100%;
    }
  }
}
</style>
