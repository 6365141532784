<template>
  <div class="property-type-switch-container">
    <div class="property-type-switch-wrapper spwa-section">
      <wds-usp
        class="property-cancellation-disclaimer"
        size="sm"
        icon="calendar-confirmation"
        :text="$t('t_CANCELFORFREEDESCRIPTION')"
      />
      <property-type-nav class="property-type-navigation" :active="propertyTypeObj.key" />
    </div>
  </div>
</template>

<script>
import { WdsUsp } from '@wds/components';
import PropertyTypeNav from '~/components/common/PropertyTypeNav';

export default {
  name: 'PropertyTypeSelection',
  components: {
    WdsUsp,
    PropertyTypeNav,
  },
  props: {
    propertyTypeObj: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.property-type-switch-container {
  margin-bottom: $wds-spacing-xxl;

  .property-type-switch-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 $wds-spacing-m;
    margin: 0 auto;

    .property-cancellation-disclaimer {
      margin: $wds-spacing-s 0;
      padding: $wds-spacing-s 0;
    }
  }

  @include tablet {
    .property-type-switch-wrapper {
      align-items: flex-start;
      padding: 0 $wds-spacing-l;

      .property-type-navigation {
        position: relative;
        left: -$wds-spacing-m;
      }
    }
  }

  @include desktop {
    margin-bottom: wds-rem(120px);
    box-shadow: 0 wds-rem(2px) 0 $wds-color-ink-lighter;

    .property-type-switch-wrapper {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      height: 56px;
      padding: 0 wds-rem(40px);
      margin: 0 auto;

      .property-type-navigation {
        position: relative;
        left: 0;
      }

      .property-cancellation-disclaimer {
        margin: wds-rem(12px) 0 $wds-spacing-xs 0;
      }
    }
  }
}
</style>
