export const AREAS_TYPES = {
  // @TODO: Add translations || Translation for words: States, Counties, Provinces, County do not exist yet.
  state: {
    pluralName: 'States',
    getTranscodeSelectArea: (transFun) => transFun('t_SELECTASTATE', { WHATSTATEISCALLED: transFun('t_STATE') }),
  },
  county: {
    pluralName: 'Counties',
    getTranscodeSelectArea: (transFun) => transFun('t_SELECTASTATE', { WHATSTATEISCALLED: 'County' }),
  },
  province: {
    pluralName: 'Provinces',
    getTranscodeSelectArea: (transFun) => transFun('t_SELECTASTATE', { WHATSTATEISCALLED: transFun('t_PROVINCE') }),
  },
};
