<template>
  <section v-if="otherLocations.length" class="other-locations-section">
    <h3 v-html="otherLocationsTitle" />
    <div class="other-locations-catalog" :class="{ expanded: expanded }">
      <div
        class="other-location-box"
        v-for="(location, idx) in otherLocations"
        :key="idx"
        :class="{ 'extra-location': idx >= maxNumPills }"
      >
        <pill
          :pill-link="location.url"
          :pill-text="location.name"
          :aria-label="viewAllPropertiesInLocationText(location)"
          :title="viewAllPropertiesInLocationText(location)"
          rel="noopener"
        />
      </div>
    </div>
    <wds-button-link
      v-if="otherLocations.length > maxNumPills"
      target="_blank"
      rel="noopener"
      class="other-locations-show-more"
      :text="$t(expanded ? 't_SHOWLESS' : 't_SHOWMORE')"
      :icon-end="expanded ? 'chevron-up' : 'chevron-down'"
      @click="toggleExpanded()"
      @keyup.enter="toggleExpanded()"
    />
  </section>
</template>

<script>
import { WdsButtonLink } from '@wds/components';
import Pill from '~/components/common/Pill';
import mq from '~/mixins/mq';

const MAX_MOBILE_NUM_PILLS = 8;
const MAX_DESKTOP_NUM_PILLS = 15;

export default {
  name: 'OtherLocationInCountry',

  mixins: [mq],

  components: {
    WdsButtonLink,
    Pill,
  },

  props: {
    locationName: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    otherLocations: {
      type: Array,
      required: false,
      default: () => [],
    },
    maxVisibleLocations: {
      type: Number,
      required: false,
      default: 0,
    },
    propertyTypeObj: {
      type: Object,
      required: true,
    },
  },

  computed: {
    otherLocationsTitle() {
      return this.title || this.$t('t_OTHERLOCATIONSINCOUNTRY', { COUNTRYNAME: `<span>${this.locationName}</span>` });
    },
    maxNumPills() {
      return this.maxVisibleLocations || (this.isSmallOrMediumScreen ? MAX_MOBILE_NUM_PILLS : MAX_DESKTOP_NUM_PILLS);
    },
  },

  data() {
    return {
      expanded: false,
    };
  },

  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    },

    viewAllPropertiesInLocationText(location) {
      return this.$t('t_VIEWALLPROPERTIESINLOCATION', {
        PROPERTYTYPE: this.$t(this.propertyTypeObj.transcode).toLowerCase(),
        LOCATION: location?.name,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.other-locations-section {
  display: flex;
  flex-direction: column;
  gap: $wds-spacing-m;
  padding: 0 $wds-spacing-m;

  h3 {
    @include title-3-bld;
    margin-bottom: $wds-spacing-s;
    color: $wds-color-ink-darker;

    ::v-deep span {
      color: $wds-color-purple;
    }
  }

  .other-locations-catalog {
    display: flex;
    flex-wrap: wrap;
    gap: $wds-spacing-m;

    .other-location-box {
      &.extra-location {
        display: none;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &.expanded .other-location-box.extra-location {
      display: block;
    }
  }

  .other-locations-show-more {
    text-transform: capitalize;
    align-self: flex-end;
  }

  @include desktop {
    padding: 0 wds-rem(40px);

    h3 {
      @include title-1-bld;
    }
  }
}
</style>
