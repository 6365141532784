<template>
  <div v-if="content" class="page-content">
    <component v-if="title" :is="headingLevel" :class="['title', `title-${titlePosition}`]" v-html="title" />
    <div class="content">
      <p v-html="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageContentSEO',

  props: {
    content: {
      type: String,
      required: true,
      default: '',
    },
    title: {
      type: String,
      required: false,
    },
    titlePosition: {
      type: String,
      required: false,
      default: 'left',
    },
    headingLevel: {
      type: String,
      required: false,
      default: 'h3',
      validator: (val) => ['h1', 'h2', 'h3', 'h4', 'h5'].includes(val),
    },
  },
};
</script>

<style lang="scss" scoped>
.page-content {
  display: block;
  padding: 0 $wds-spacing-m;
  margin: $wds-spacing-m $wds-spacing-m 0;

  .title {
    @include title-3-bld;
    text-align: left;
    margin-bottom: $wds-spacing-l;
    color: $wds-color-ink-darker;

    &.title-center {
      text-align: center;
    }

    ::v-deep span {
      color: $wds-color-purple;
    }
  }

  ::v-deep .content {
    p {
      @include body-1-reg;
      color: $wds-color-ink-dark;
      text-align: left;
      margin-bottom: $wds-spacing-m;
    }

    a {
      @include body-1-bld;
      color: $wds-color-ink-dark;
      text-decoration: underline;
      outline: none;
      padding: $wds-spacing-s 0;
      background-color: transparent;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
        color: $wds-color-ink;
      }

      &:focus-visible {
        box-shadow: 0 0 0 $wds-spacing-xxs $wds-color-white, 0 0 0 $wds-spacing-xs $wds-color-ink-darker;
      }
    }

    h2 {
      @include title-3-bld;
      text-align: left;
      color: $wds-color-ink-darker;
    }

    ul,
    li {
      @include body-1-reg;
    }

    ul {
      margin-bottom: $wds-spacing-m;
    }

    li {
      list-style-position: outside;
      list-style-type: disc;
      margin: 0 0 $wds-spacing-s $wds-spacing-m;

      h3 {
        @include body-1-reg;
        text-align: left;
      }
    }
  }

  @include desktop {
    padding: 0 wds-rem(40px);

    .title {
      @include title-1-bld;
    }
  }
}
</style>
