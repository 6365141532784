<template>
  <div class="property-type-nav-links">
    <wds-nav-link
      v-for="propType in propertyTypes"
      :key="propType.id"
      :name="propType.key"
      :label="$t(propType.transcode)"
      :active="active === propType.key"
      :link="propType.link"
    />
  </div>
</template>

<script>
import { WdsNavLink } from '@wds/components';
import { PROPERTY_TYPES } from '~/config/property-types';

export default {
  name: 'PropertyTypeNav',
  components: {
    WdsNavLink,
  },
  props: {
    active: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    propertyTypes() {
      return PROPERTY_TYPES.map((propType) => {
        return {
          ...propType,
          link: this.$url.getReplacedPropertyTypeUrl({
            propertyTypeSlug: this.$t(propType.slug),
          }),
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.property-type-nav-links {
  display: inline-flex;
  gap: $wds-spacing-s;
}
</style>
