<template functional>
  <div v-if="props.content" class="properties-count">{{ props.content }}</div>
</template>

<script>
export default {
  name: 'PropertiesCount',

  props: {
    content: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.properties-count {
  color: $wds-color-white;
  font-size: $wds-font-size-body-2;
  padding: 0 $wds-spacing-m;
}
</style>
