<template>
  <div>
    <template>
      <Header
        :free-cancellation-text="$t('t_CANCELFORFREEDESCRIPTION')"
        :headline="getMainTitle()"
        :sub-label="getPropertiesCountText()"
      />
    </template>

    <main class="container">
      <template>
        <wds-bread-crumbs
          class="spwa-section breadcrumbs"
          :breadcrumbs="crumbs"
          :max-crumbs-shown="getCrumbsShownByViewport"
          :reversed="false"
        />
        <div class="spwa-section cancellation-and-property-type">
          <property-type-nav class="property-type-navigation" :active="propertyTypeObj.key" />
        </div>
        <div v-if="experiment_social_banner" class="social-banner-wrapper">
          <hw-social-banner />
        </div>
      </template>

      <popular-properties
        class="spwa-section"
        tag="section"
        :property-type-obj="propertyTypeObj"
        :location-name="countryInfo.name"
        :location-list="topPropertiesInLocation"
      />

      <client-only>
        <section class="spwa-section map-section">
          <mapbox-map
            tag="div"
            :map-config="getMapConfigs"
            :marker-list="getMapMakersList"
            :property-type-obj="propertyTypeObj"
          >
          </mapbox-map>
        </section>
      </client-only>

      <average-city-prices
        v-if="averageCityPrices"
        class="spwa-section"
        tag="section"
        :average-city-prices-list="averageCityPrices"
        :property-type-obj="propertyTypeObj"
      />

      <other-locations-in-country
        v-if="areasPoliticalData"
        class="spwa-section"
        :location-name="countryInfo.name"
        :other-locations="areasPoliticalData"
        :title="createTitle()"
        :propertyTypeObj="propertyTypeObj"
      />
      <other-locations-in-country
        v-if="regionsTouristData"
        class="spwa-section"
        :location-name="countryInfo.name"
        :other-locations="regionsTouristData"
        :title="$t('t_POPULARAREASINCOUNTRY', { COUNTRYNAME: `<span>${countryInfo.name}</span>` })"
        :propertyTypeObj="propertyTypeObj"
      />
    </main>

    <page-content-seo
      class="spwa-section"
      :title="$t('t_ABOUTLOCATION', { LOCATION: `<span>${countryInfo.name}</span>` })"
      :content="pageContent"
    />

    <Footer />

    <client-only>
      <CookieBar />
    </client-only>
  </div>
</template>

<script>
import PropertyTypeSelection from '~/components/common/PropertyTypeSelection';
import PropertyTypeNav from '~/components/common/PropertyTypeNav';
import PopularProperties from '~/components/common/PopularProperties';
import AverageCityPrices from '~/components/common/AverageCityPrices';
import PageContentSeo from '~/components/common/PageContentSeo/index';
import OtherLocationsInCountry from '~/components/city/OtherLocationsInCountry';
import Header from '~/components/common/Header';
import Footer from '~/components/common/Footer';
import BreadCrumbs from '~/components/common/BreadCrumbs/index';
import RwdHeaderNav from '~/components/hwrwd/common/Header/HeaderNav/index';
import RwdHeroImage from '~/components/hwrwd/common/Header/HeroImage/index';
import RwdHeaderTitle from '~/components/hwrwd/common/Header/HeaderTitle/index';
import RwdHeaderPropertiesCount from '~/components/hwrwd/common/Header/HeaderPropertiesCount/index';
import RwdSearch from '~/components/hwrwd/common/Search/index';
import HwSocialBanner from '~/components/common/HwSocialBanner';
import HwHeadline from '~/components/common/HwHeadline';
import { WdsUsp, WdsBreadCrumbs } from '@wds/components';
import { DEFAULT_LANGUAGE } from '~/config/languages';
import { notFoundErrorPage, internalServerErrorPage, getHttpErrors } from '~/lib/http';
import TranscodeInterpolate from '~/models/TranscodeInterpolate';
import ogTags from '~/lib/ogTags';
import twitterTags from '~/lib/twitterTags';
import { socialMediaTags } from '~/lib/socialMediaMetaTags';
import mq from '~/mixins/mq';
import { AREAS_TYPES } from '~/config/areas-types';
import { UrlBuilder } from '~/lib/url';

const entryPageName = 'COUNTRY';
const locationKey = 'country';

const gtmPageName = 'countryLanding';
const gtmClassification = 'country';

export default {
  name: 'CountryPage',

  middleware: 'redirect',

  layout: 'hwrwd',

  mixins: [mq],

  components: {
    PropertyTypeSelection,
    PropertyTypeNav,
    PopularProperties,
    AverageCityPrices,
    PageContentSeo,
    OtherLocationsInCountry,
    Header,
    Footer,
    BreadCrumbs,
    RwdHeaderNav,
    RwdHeroImage,
    RwdHeaderTitle,
    RwdHeaderPropertiesCount,
    RwdSearch,
    MapboxMap: /* istanbul ignore next */ () =>
      import(/* webpackMode: "lazy" */ '~/components/common/Mapbox/MapboxMap'),
    HwSocialBanner,
    HwHeadline,
    WdsUsp,
    WdsBreadCrumbs,
    CookieBar: /* istanbul ignore next */ () => import(/* webpackMode: "lazy" */ '~/components/common/CookieBar/index'),
  },

  async asyncData({ req, res, app, store, params, query, error }) {
    store.commit('session/setEntryPoint', entryPageName);
    const urlParams = params || app.router.history.current.params;
    const langUrl = store.state.session.language.tag || DEFAULT_LANGUAGE.tag;
    app.i18n.locale = langUrl;
    const userCurrency = store.state.session.currency.code;

    let continentUrl = urlParams.continent || null;
    let countryUrl = urlParams.country || null;
    let continentId = null;
    let countryId = null;
    let countryInfo = {};

    const propertyTypeObj = app.$routeManager.validatePropertyTypeParam(urlParams.propertyType, store);
    const propertyTypeUrl = propertyTypeObj.slug;
    const propertyTypeKey = propertyTypeObj.key;

    const { static_socialBanner: experiment_social_banner, web_sustainabilityBadges } =
      store.$optimizely.isFeatureListEnabled(['static_socialBanner', 'web_sustainabilityBadges']);

    let countryObj;

    try {
      countryObj = await app.$routeManager.validateCountryParam(
        langUrl,
        userCurrency,
        propertyTypeKey,
        countryUrl,
        app,
        store,
        req,
        res
      );
    } catch (err) {
      if (err) {
        console.error(err);
        return err && err.error?.status == getHttpErrors('NOT_FOUND')
          ? error(notFoundErrorPage('Country not found.'))
          : error(internalServerErrorPage(`Country error: ${err.errors?.error?.message?.description}`));
      }
    }

    const seoServices = await app.$api.getSpApi().getServices('seo');

    if (!countryObj || countryObj.id === null) {
      return error(notFoundErrorPage('Country not found.'));
    }

    if (countryObj.name) {
      countryId = countryObj.id;
      countryUrl = countryObj.urlFriendlyName;
      continentId = countryObj.continentId;
      continentUrl = countryObj.urlFriendlyContinent;
      countryInfo = countryObj;
    }

    const urlData = app.$routeManager.getUrlData(propertyTypeUrl, continentUrl, countryUrl);

    /* Redirect user to previous entity (ex: city -> country -> continent) */
    if (countryInfo && countryInfo.numberOfProperties === 0) {
      const redirectEntityUrl = `${app.$env.STATIC_URL}${propertyTypeUrl}/${continentUrl}`;
      await app.$routeManager.redirectEntityLevelUp(app, store, redirectEntityUrl, query);
    }

    await app.$routeManager.redirectRoute(app, store, urlData, query);

    const topServices = await app.$api.getSpApi().getServices('top');
    const topPropertiesInLocation = await topServices.getTopPropertiesInLocation(
      langUrl,
      userCurrency,
      propertyTypeKey,
      locationKey,
      countryId
    );

    /* Bring sustainability info from HWAPI */
    /* TODO: Remove this block once SPAPI returns sustainability info */
    if (web_sustainabilityBadges) {
      const hwPropertyService = await app.$api.getHwApi().getServices('properties');
      topPropertiesInLocation?.cities.map((city) => {
        city.properties.map(async (property) => {
          const hwProperty = await hwPropertyService.getPropertyDetailsByPropertyId(
            property.id,
            store.state.session.language.code
          );
          property.sustainability = hwProperty?.sustainability || null;
          return property;
        });
        return city;
      });
    }

    const countriesServices = await app.$api.getSpApi().getServices('countries');

    const areasPoliticalData = await countriesServices.getAllAreasByCountryId(
      langUrl,
      userCurrency,
      propertyTypeKey,
      countryId
    );
    if (areasPoliticalData?.areas) {
      areasPoliticalData.areas.forEach((area) => {
        area.propertyTypeSlug = propertyTypeUrl;
        area.url = app.$url.getAreasUrl(area, area);
      });
    }

    const regionsTouristData = await countriesServices.getAllRegionsByCountryId(
      langUrl,
      userCurrency,
      propertyTypeKey,
      countryId
    );
    if (regionsTouristData?.regions) {
      regionsTouristData.regions.forEach((region) => {
        region.propertyTypeSlug = propertyTypeUrl;
        region.url = app.$url.getRegionsUrl(region, region);
      });
    }

    const averageCityPrices = await countriesServices.getAverageCityPricesByName(
      langUrl,
      userCurrency,
      propertyTypeKey,
      countryUrl
    );

    const fullCountryPath = `${propertyTypeUrl}/${continentUrl}/${countryUrl}/`;

    countryInfo.fullPath = `${fullCountryPath}`;
    countryInfo.fullUrl = `${app.$env.STATIC_URL}${fullCountryPath}`;
    countryInfo.canonicalUrl = `${app.$env['BASE_URL_PROTOCOL']}://${app.$env[store.state.session.language.baseUrl]}${
      countryInfo.fullUrl
    }`;
    countryInfo.propertyTypeSingular = app.i18n.t(propertyTypeObj.transcode_s);
    countryInfo.propertyTypePlural = app.i18n.t(propertyTypeObj.transcode);
    countryInfo.propertyTypeKey = propertyTypeKey;
    countryInfo.propertyTypeSlug = propertyTypeUrl;

    countryInfo.images = {
      mobile: countryInfo?.image?.mobile
        ? new UrlBuilder(countryInfo.image.mobile).addProtocol()
        : `${store.$env['STATIC_URL']}image_default_mobile.jpg`,
      tablet: countryInfo?.image?.tablet
        ? new UrlBuilder(countryInfo.image.mobile).addProtocol()
        : `${store.$env['STATIC_URL']}image_default_tablet.jpg`,
      desktop: countryInfo?.image?.desktop
        ? new UrlBuilder(countryInfo.image.mobile).addProtocol()
        : `${store.$env['STATIC_URL']}image_default_desktop.jpg`,
      heroImage: countryInfo?.image?.heroImage
        ? new UrlBuilder(countryInfo.image.mobile).addProtocol()
        : `${store.$env['STATIC_URL']}image_default_desktop.jpg`,
    };

    let seoData = seoServices.getCountryTags(langUrl, countryId, propertyTypeKey);

    seoData = await seoData;

    let ogMetaTags = [];
    let twitterMetaTags = [];
    let linkTags = app.$linkTags.getLinkTag();

    if (seoData) {
      const defaultPageSeoTitle = app.i18n.t('t_PROPERTYTYPEINLOCATION', {
        PROPERTYTYPE: countryInfo?.propertyTypePlural,
        LOCATION: countryInfo?.name,
      });
      const customSeoTitle = seoData?.h1 || defaultPageSeoTitle;

      seoData.title = seoData?.title || seoData?.ogTitle || customSeoTitle || app.i18n.t('t_DEFAULTSEOTITLE');
      seoData.ogTitle = seoData?.ogTitle || seoData?.title || customSeoTitle || app.i18n.t('t_DEFAULTSEOTITLE');
      seoData.description =
        (seoData?.description || seoData?.ogDescription)?.replace(/\r?\n|\r/g, '') ||
        app.i18n.t('t_DEFAULTSEODESCRIPTION');
      seoData.ogDescription =
        (seoData?.ogDescription || seoData?.description)?.replace(/\r?\n|\r/g, '') ||
        app.i18n.t('t_DEFAULTSEODESCRIPTION');
      seoData.ogImage = countryInfo?.images?.heroImage;
      seoData.ogUrl = seoData?.canonical || countryInfo?.canonicalUrl;
      const tradReplacements = {
        PROPCOUNT: countryInfo?.numberOfProperties,
        PROPERTYTYPE: countryInfo?.propertyTypePlural,
        PROPERTYTYPES: countryInfo?.propertyTypePlural,
        LOCATIONNAME: `${countryInfo?.name}, ${countryInfo?.continent}`,
        YEAR: new Date().getFullYear(),
        REVIEWCOUNT: countryInfo?.numberOfReviews,
        REVIEWSCOUNT: countryInfo?.numberOfReviews,
        CONTINENT: countryInfo?.continent,
        CHOSENCONTINENT: countryInfo?.continent,
        CHOSENCOUNTRY: countryInfo?.name,
        COUNTRY: countryInfo?.name,
        ACTIVEFILTER: '',
      };
      const transcodeInterpolate = new TranscodeInterpolate();
      const propertiesToReplace = [
        'title',
        'description',
        'copy',
        'ogTitle',
        'ogDescription',
        'heading',
        'keywords',
        'h1',
      ];
      propertiesToReplace.forEach((property) =>
        seoData[property]
          ? (seoData[property] = transcodeInterpolate.interpolate(seoData[property], tradReplacements))
          : (seoData[property] = '')
      );

      const pageOgTags = ogTags(seoData.ogTitle, seoData.ogImage, seoData.ogUrl, seoData.ogDescription, 'country');
      const pageTwitterTags = twitterTags(
        'summary_large_image',
        seoData.ogTitle,
        seoData.ogImage,
        seoData.ogUrl,
        seoData.ogDescription
      );
      ogMetaTags = socialMediaTags(pageOgTags);
      twitterMetaTags = socialMediaTags(pageTwitterTags);

      if (countryInfo) {
        linkTags = app.$linkTags.getCanonicalTag(linkTags, countryInfo.canonicalUrl);
        const linkArgs = [
          linkTags,
          app.$url.getHrefLangUrlforCountry,
          countryInfo?.locationTranslations,
          countryInfo?.propertyTypeKey,
          countryInfo?.propertyTypePlural,
        ];
        linkTags = app.$linkTags.getAlternateTagWithPropertyType(...linkArgs);
        linkTags = app.$linkTags.getDefaultAlternateTagWithPropertyType(...linkArgs);

        store.dispatch('route/setAllRoutesByLang', linkTags.getLinksByLang());
      }
    }

    const locationData = {
      propertyTypeSlug: propertyTypeObj.slug,
      urlFriendlyContinent: countryInfo.urlFriendlyContinent,
      urlFriendlyCountry: countryInfo.urlFriendlyName,
    };

    const crumbs = [
      {
        label: app.i18n.t('t_A11YBCTITLEPROPERTYTYPE', {
          PROPERTYTYPE: countryInfo.propertyTypePlural,
        }),
        title: countryInfo.propertyTypePlural,
        link: app.$url.getWorldPageUrl(locationData),
      },
      {
        label: app.i18n.t('t_A11YBCTITLELOCATION', {
          LOCATIONNAME: countryInfo.continent,
          PROPERTYTYPE: countryInfo.propertyTypePlural,
        }),
        title: app.i18n.t('t_A11YBCTEXTLOCATION', {
          LOCATIONNAME: countryInfo.continent,
          PROPERTYTYPE: countryInfo.propertyTypePlural,
        }),
        link: app.$url.getContinentPageUrl(locationData),
      },
      {
        label: app.i18n.t('t_A11YBCTITLELOCATION', {
          LOCATIONNAME: countryInfo.name,
          PROPERTYTYPE: countryInfo.propertyTypePlural,
        }),
        title: countryInfo.name,
        link: app.$url.getCountryPageUrl(locationData),
      },
    ];

    return {
      crumbs,
      seoData,
      countryInfo,
      continentUrl,
      continentId,
      countryUrl,
      countryId,
      propertyTypeObj,
      pageContent: seoData?.copy || '',
      areasPoliticalData: areasPoliticalData?.areas || null,
      regionsTouristData: regionsTouristData?.regions || null,
      averageCityPrices: averageCityPrices?.cities || null,
      topPropertiesInLocation: topPropertiesInLocation?.cities || null,
      experiment_social_banner,
      ogMetaTags,
      twitterMetaTags,
      linkTags,
    };
  },

  head() {
    if (this.seoData) {
      this.$structuredMarkup.addDefault();

      if (this.countryInfo) {
        this.addCountrySchema();
      }

      return {
        title: this.seoData.title,
        meta: [
          { hid: 'content-language', name: 'content-language', content: this.$store.state.session.language.tag },
          { hid: 'description', name: 'description', content: this.seoData.description },
          { hid: 'keywords', name: 'keywords', content: this.seoData.keywords },
          {
            hid: 'robots',
            name: 'robots',
            content: 'index, follow, max-image-preview:large',
          },
          ...this.ogMetaTags,
          ...this.twitterMetaTags,
        ],
        link: this.linkTags.data,
        script: [
          {
            hid: 'ldjson-schema',
            innerHTML: JSON.stringify(this.$structuredMarkup.getFullSchema()),
            type: 'application/ld+json',
          },
        ],
        __dangerouslyDisableSanitizersByTagID: {
          'ldjson-schema': ['innerHTML'],
        },
      };
    }
  },

  data() {
    return {
      tracking: null,
      countryUrl: this.$router.history.current.params.country,
      locationType: 'city',
    };
  },

  provide() {
    return {
      locationInfoFromPage: {
        type: 'country',
        id: this.countryInfo.id,
        name: this.countryInfo?.name,
        continentId: this.countryInfo?.continentId,
        continentName: this.countryInfo?.continent,
        urlFriendlyName: this.countryInfo?.urlFriendlyName,
        urlFriendlyCountry: this.countryInfo?.urlFriendlyName,
        urlFriendlyContinent: this.countryInfo?.urlFriendlyContinent,
        image: this.countryInfo?.image,
      },
      trackingInfoFromPage: {
        gtmPageName,
        gtmClassification,
      },
    };
  },

  computed: {
    getMapConfigs() {
      return {
        scrollZoom: false,
        center: [this.countryInfo.longitude, this.countryInfo.latitude],
        zoom: 5,
      };
    },

    getMapMakersList() {
      const areasOrCities = this.countryInfo.areas.length ? this.countryInfo.areas : this.countryInfo.cities;

      return areasOrCities
        .filter((location) => location?.totalPropertiesAvailable)
        .reduce((markers, location) => {
          const locationObject = {
            id: location.id,
            name: location.name,
            latitude: location.latitude,
            longitude: location.longitude,
            numProperties: location?.totalPropertiesAvailable || 0,
          };

          if (location?.cities) {
            locationObject.url = this.$url.getAreasUrl(
              {
                propertyTypeSlug: this.propertyTypeObj.slug,
                urlFriendlyContinent: location.urlFriendlyContinent,
                urlFriendlyCountry: location.urlFriendlyCountry,
              },
              {
                urlFriendlyName: location.urlFriendlyName,
              }
            );
            locationObject.topCities = location.cities.map((city) => ({
              id: city.id,
              name: city.name,
              urlFriendlyName: city.urlFriendlyName,
              urlFriendlyCountry: city.urlFriendlyCountry,
              urlFriendlyContinent: city.urlFriendlyContinent,
            }));
          } else {
            locationObject.url = this.$url.getCityUrl({
              propertyTypeSlug: this.propertyTypeObj.slug,
              urlFriendlyContinent: location.urlFriendlyContinent,
              urlFriendlyCountry: location.urlFriendlyCountry,
              urlFriendlyCity: location.urlFriendlyName,
            });
            locationObject.citySummary = {
              numProperties: location.totalPropertiesAvailable,
              avgDormPrice: location.avgDormPrice,
              avgPrivatePrice: location.avgPrivatePrice,
            };
          }

          markers.push(locationObject);
          return markers;
        }, []);
    },

    areaType() {
      return this.areasPoliticalData && this.areasPoliticalData[0]?.type ? this.areasPoliticalData[0].type : 'province';
    },

    getCrumbsShownByViewport() {
      return this.isExtraSmallScreen ? 2 : null;
    },
  },

  mounted() {
    this.trackPageViews();
    this.locationType = this.countryInfo?.areas?.length ? 'area' : 'city';
  },

  methods: {
    async trackPageViews() {
      this.tracking = await this.$tracking.GenericPage();

      const trackingInfo = {
        gtm: {
          gtmPropertyType: this.propertyTypeObj.type.toLowerCase(),
          gtmCanonicalUrl: this.countryInfo.canonicalUrl,
          gtmContinent: this.countryInfo.tracking.continent,
          gtmCountry: this.countryInfo.tracking.name,
          gtmPageName,
          gtmClassification,
        },
        segment: {
          event_name: 'Other Static Page Viewed',
          page_type: 'countryLanding',
          property_type: this.propertyTypeObj.type.toLowerCase(),
          destination_country: this.countryInfo.tracking.name,
          destination_continent: this.countryInfo.tracking.continent,
        },
      };

      await this.tracking?.trackOnPageLoad(trackingInfo.gtm, trackingInfo.segment);

      await this.$store.dispatch('tracking/setPageType', gtmPageName);
    },

    getMainTitle() {
      if (this?.seoData?.h1) {
        return this.seoData.h1;
      }

      return this.$i18n.t('t_PROPERTYTYPEINLOCATION', {
        PROPERTYTYPE: this.countryInfo.propertyTypePlural,
        LOCATION: `${this.countryInfo.name}`,
      });
    },

    getPropertiesCountText() {
      let translation = this.$i18n.t('t_RWDWEHAVEXHOSTELSINLOCATIONSHORT', {
        NUMOFPROPERTIES: this.countryInfo.numberOfProperties,
        NUMOFCITIES: this.countryInfo.numberOfCities,
        PROPERTYTYPE:
          this.countryInfo.numberOfProperties === 1
            ? this.countryInfo.propertyTypeSingular
            : this.countryInfo.propertyTypePlural,
        CITYORCITIES: this.countryInfo.numberOfCities === 1 ? this.$i18n.t('t_CITY') : this.$i18n.t('t_CITIES'),
        LOCATION: `${this.countryInfo.name}, ${this.countryInfo.continent}`,
      });

      if (this.countryInfo.avgRating > 0) {
        translation =
          translation +
          ' ' +
          this.$i18n.t('t_RWDWEHAVEXHOSTELSINLOCATIONRATINGS', {
            AVGRATING: this.$formatter.formatRating(this.countryInfo.avgRating),
            NUMOFREVIEWS: this.countryInfo.numberOfReviews,
          });
      }
      return translation;
    },

    getHeadlineData() {
      return {
        mainTitle: this.getMainTitle().replace(this.countryInfo.name, `<span>${this.countryInfo.name}</span>`),
        subTitle: this.getPropertiesCountText(),
      };
    },

    addCountrySchema() {
      this.$structuredMarkup.addGraph({
        '@type': 'Country',
        name: this.countryInfo.name,
        description: this.seoData.ogDescription,
      });
    },

    createTitle() {
      return this.$t('t_STATESINCOUNTRY', {
        STATES: AREAS_TYPES[this.areaType].pluralName,
        COUNTRY: `<span>${this.countryInfo.name}</span>`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  position: relative;
  min-height: 240px;

  .info-area {
    position: absolute;
    bottom: $wds-spacing-m;
    width: 100%;

    .breadcrumbs-wrapper {
      margin: 0 $wds-spacing-s;
    }

    ::v-deep .properties-count {
      display: none;

      @media screen and (min-width: $screen-medium-large) {
        display: initial;
      }
    }
  }

  @media screen and (min-width: $screen-medium-large) {
    min-height: 430px;
  }
}

main {
  .social-banner-wrapper {
    margin-top: $wds-spacing-m;
  }

  .breadcrumbs,
  .headline,
  .cancellation-and-property-type {
    margin: 0 auto !important;
  }

  .headline {
    padding: 0 $wds-spacing-m $wds-spacing-m;
  }

  .cancellation-and-property-type {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 2px solid $wds-color-ink-lighter;
    border-bottom: 2px solid $wds-color-ink-lighter;

    .cancellation-disclaimer {
      padding: $wds-spacing-s $wds-spacing-m;
    }
  }

  .map-section {
    position: relative;
    height: wds-rem(560px);
    width: 100%;
  }

  @include tablet {
    .social-banner-wrapper {
      margin: $wds-spacing-m $wds-spacing-m;
    }

    .breadcrumbs {
      padding: 0 $wds-spacing-s;
    }

    .cancellation-and-property-type {
      .cancellation-disclaimer {
        padding: $wds-spacing-s $wds-spacing-m;
      }

      .property-type-navigation {
        padding: 0;
      }
    }
  }

  @include desktop {
    .social-banner-wrapper {
      margin: $wds-spacing-m $wds-spacing-xl;
    }

    .breadcrumbs {
      padding: 0 $wds-spacing-xl;
    }

    .headline {
      padding: 0 wds-rem(40px) $wds-spacing-m;
      display: flex;
      flex-direction: row;
      gap: $wds-spacing-m;
      align-items: center;

      ::v-deep .headline-main-title {
        white-space: nowrap;
      }
    }

    .cancellation-and-property-type {
      flex-direction: row;
      justify-content: center;

      .cancellation-disclaimer {
        padding: $wds-spacing-s 0 $wds-spacing-s wds-rem(40px);
      }

      .property-type-navigation {
        padding: 0 $wds-spacing-l 0 0;
      }
    }

    .map-section {
      padding: 0 wds-rem(40px);

      ::v-deep .map-canvas {
        border-radius: $wds-border-radius-m;
      }
    }
  }
}

a:focus-visible {
  box-shadow: 0 0 0 $wds-spacing-xxs $wds-color-white, 0 0 0 $wds-spacing-xs $wds-color-ink-darker;
}

.country-wrapper {
  padding: 0 $wds-spacing-m;
}

.country-map {
  position: relative;
  width: 100%;
  min-height: wds-rem(500px);
  max-width: wds-rem(1090px);
  margin-bottom: $wds-spacing-l;
  @include desktop {
    height: wds-rem(600px);
    margin-bottom: 0;
  }
}
</style>

<style lang="scss" scoped>
section[name='ms-map'] {
  width: 100%;
  height: wds-rem(500px);
}
</style>
