<template>
  <div class="headline-container" :class="{ 'with-map': showMapButton }">
    <h1 class="headline-main-title" v-html="mainTitle" />
    <div class="headline-map-link" v-if="showMapButton">
      <wds-pill-active
        class="headline-map-button"
        :text="$t('t_MAP')"
        icon-start="map"
        :icon-end="openedMapButton ? 'close' : null"
        :href="mapLink"
        @click="handleMapClick"
      />
    </div>
    <div v-if="subTitle" class="headline-sub-title" v-html="subTitle" />
  </div>
</template>

<script>
import { WdsPillSelect, WdsPillActive } from '@wds/components';

export default {
  name: 'HwHeadline',

  components: {
    WdsPillSelect,
    WdsPillActive,
  },

  props: {
    mainTitle: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
    },
    showMapButton: {
      type: Boolean,
      default: false,
    },
    openedMapButton: {
      type: Boolean,
      default: false,
    },
    mapLink: {
      type: String,
      default: '',
    },
  },

  methods: {
    handleMapClick() {
      this.$emit('map-button-click');
    },
  },
};
</script>

<style lang="scss" scoped>
.headline-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.with-map {
    display: grid;
    grid-template-areas:
      'main-title map-link'
      'sub-title map-link';
    grid-template-columns: 1fr auto;
    column-gap: $wds-spacing-s;
  }

  .headline-main-title {
    grid-area: main-title;
    @include title-3-bld;

    ::v-deep span {
      color: $wds-color-purple;
    }
  }

  .headline-map-link {
    grid-area: map-link;
    fill: $wds-color-ink-dark;
  }

  .headline-sub-title {
    grid-area: sub-title;
    @include body-2-reg;
  }
}
</style>
