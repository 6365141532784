export default {
  computed: {
    getRatingScoreTranslations() {
      return {
        tNew: this.$t('t_RATINGNEW'),
        tReviews: this.$t('t_RATINGREVIEWS'),
        tFabulous: this.$t('t_RATINGFABULOUS'),
        tSuperb: this.$t('t_RATINGSUPERB'),
        tVerygood: this.$t('t_RATINGVERYGOOD'),
        tGood: this.$t('t_RATINGGOOD'),
      };
    },
  },
};
