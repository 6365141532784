<template>
  <div class="search-form-wrapper">
    <wds-search-form-stacked
      class="search-form-stacked-container"
      v-bind="searchFormStackedParams"
      @destination-input="handleDestinationSearch"
      @destination-item-selected="handleDestinationItemSelected"
      @destination-selected="handleDestinationFieldClicked"
      @dates-selected="handleDatesFieldClicked"
      @guests-selected="handleGuestsFieldClicked"
      @guests-change="handleGuestsSelected"
      @guests-group-type-change="handleGuestsGroupTypeChanged"
      @guests-group-ages-change="handleGuestsGroupAgesChanged"
      @current-location="handleCurrentLocation"
      @search-form-submit="submit"
    />
    <wds-search-form-inline
      ref="searchFormInline"
      class="search-form-inline-container"
      v-bind="searchFormInlineParams"
      @destination-input="handleDestinationSearch"
      @destination-item-selected="handleDestinationItemSelected"
      @destination-selected="handleDestinationFieldClicked"
      @dates-selected="handleDatesFieldClicked"
      @guests-selected="handleGuestsFieldClicked"
      @guests-change="handleGuestsSelected"
      @guests-group-type-change="handleGuestsGroupTypeChanged"
      @guests-group-ages-change="handleGuestsGroupAgesChanged"
      @current-location="handleCurrentLocation"
      @search-form-submit="submit"
      @hook:mounted="handleSearchFormReady"
    />
    <wds-search-form-wizard
      class="search-form-wizard-container"
      v-bind="searchFormWizardParams"
      :open-wizard="wizardIsOpen"
      @destination-input="handleDestinationSearch"
      @destination-item-selected="handleDestinationItemSelected"
      @destination-selected="handleDestinationItemSelected"
      @dates-selected="handleDatesSelected"
      @guests-selected="handleGuestsSelected"
      @guests-group-type-change="handleGuestsGroupTypeChanged"
      @guests-group-ages-change="handleGuestsGroupAgesChanged"
      @current-location="handleCurrentLocation"
      @search-form-submit="submit"
      @search-form-close="wizardIsOpen = false"
    />
  </div>
</template>

<script>
import { WdsSearchFormInline, WdsSearchFormStacked, WdsSearchFormWizard } from '@wds/components';
import mq from '~/mixins/mq';
import DatePickerMixin from '~/mixins/Search/DatePicker';
import { mapActions, mapGetters } from 'vuex';
import search from '~/config/search';
import groups from '~/config/groups';
import { checkinDate, leadTime, numberOfNights } from '~/lib/timeFromPWA';
import { DEFAULT_LANGUAGE } from '~/config/languages';
import { PROPERTY_TYPE_DEFAULT, PROPERTY_TYPES } from '~/config/property-types';
import { TRACKING_EMPTY } from '~/tracking/variables';
import { setHref } from '~/lib/dom';

export const EMPTY_DATE = { year: 0, month: 0, day: 0 };
export default {
  name: 'HwSearchForm',

  inject: {
    locationInfoFromPage: {
      default: () => ({}),
    },
    headerExperimentVars: {
      default: () => ({}),
    },
  },

  mixins: [mq, DatePickerMixin],

  components: {
    WdsSearchFormStacked,
    WdsSearchFormInline,
    WdsSearchFormWizard,
  },

  data() {
    return {
      tracking: null,
      wizardIsOpen: false,
      wizardActiveStep: 'destination',
      maxLocations: 20,
      timeout: null,
      debounce: 600,
      searchDefaultLocation: {},
      searchResults: [],
    };
  },

  computed: {
    ...mapGetters({
      getSearchLocation: 'searchForm/getSearchLocation',
      getSearchNumberOfGuests: 'searchForm/getSearchNumberOfGuests',
      getSearchGroupType: 'searchForm/getSearchGroupType',
      getSearchGroupAge: 'searchForm/getSearchGroupAge',
      getIsSearchActivated: 'searchForm/getIsSearchActivated',
      getCurrentLanguage: 'session/getCurrentLanguage',
      getCurrentCurrency: 'session/getCurrentCurrency',
      isPropertyPage: 'route/isPropertyPage',
      isCityPage: 'route/isCityPage',
      isDistrictPage: 'route/isDistrictPage',
    }),

    getGuestsGroupTypes() {
      return groups.groupTypes.map((gtype) => ({
        ...gtype,
        label: this.$t(gtype.label),
        value: String(gtype.value),
      }));
    },

    searchFormCommonParams() {
      return {
        lang: this.$store.state.session.language.lang,
        destinationDefault: this.searchDefaultLocation,
        destinationSearchResults: this.searchResults,
        destinationRecentSearches: null,
        dateCheckIn: this.headerExperimentVars['empty-dates'] ? EMPTY_DATE : this.getSearchCheckIn(),
        dateCheckOut: this.headerExperimentVars['empty-dates'] ? EMPTY_DATE : this.getSearchCheckOut(),
        maxNightsOfStay: search.extendedMaxNights,
        guestsQty: this.getSearchNumberOfGuests.guests,
        guestsMax: search.maxGuests,
        groupThreshold: groups.minGuests - 1,
        groupTypes: this.getGuestsGroupTypes,
        selectedGroupType: this.getSearchGroupType?.value,
        selectedGroupAges: this.getSearchGroupAge,
        groupAgeRanges: groups.ageRanges,
        translations: this.getSearchFormTranslations,
      };
    },

    searchFormStackedParams() {
      return {
        size: this.isBelowMediumScreen ? 'small' : 'large',
        ...this.searchFormCommonParams,
      };
    },

    searchFormInlineParams() {
      return {
        size: 'large',
        ...this.searchFormCommonParams,
      };
    },

    searchFormWizardParams() {
      return {
        activeStep: this.wizardActiveStep,
        focusDestinationOnOpen: true,
        ...this.searchFormCommonParams,
      };
    },

    getDestinationObject() {
      const location = this?.locationInfoFromPage;
      switch (location?.type) {
        case 'city':
          return {
            id: location?.id || 0,
            type: location.type,
            label: location?.name,
            labelSmall: location?.countryName,
            value: (location?.id || 0).toString(),
          };
        case 'citydistricts':
          return {
            id: location?.id || 0,
            cityId: location?.cityId || 0,
            type: location.type,
            label: location?.name,
            labelSmall: [location?.cityName, location?.countryName].join(', '),
            value: (location?.id || 0).toString(),
          };
        default:
          return {};
      }
    },

    getSearchFormTranslations() {
      return {
        tAgeRanges: this.$t('t_RWDAGERANGES'),
        tApplyDates: this.$t('t_FORMINPUTAPPLY'),
        tCheckin: this.$t('t_FORMINPUTCHECKIN'),
        tCheckout: this.$t('t_FORMINPUTCHECKOUT'),
        tClearDates: this.$t('t_FORMINPUTCLEAR'),
        tCurrentLocation: this.$t('t_FORMINPUTCURRENTLOCATION'),
        tDates: this.$t('t_FORMINPUTDATES'),
        tDatesNoCheckInError: this.$t('t_FORMINPUTSELECTCHECKIN'),
        tDatesNoCheckOutError: this.$t('t_FORMINPUTSELECTCHECKOUT'),
        tDestination: this.$t('t_FORMINPUTDESTINATION'),
        tDestinationCurrentLocationError: this.$t('t_FORMERRORNOCURRENTLOCATION'),
        tDestinationEmptyResultsError: this.$t('t_FORMERROREMPTYRESULTS'),
        tDestinationFieldLabel: this.$t('t_FORMINPUTWHEREDOYOUWANTTOGO'),
        tGroupType: this.$t('t_RWDGROUPTYPE'),
        tGuests: this.$t('t_FORMINPUTGUESTS'),
        tGuestsNoAgeRangeError: this.$t('t_RWDCHOOSEGROUPTYPE'),
        tGuestsNoGroupTypeError: this.$t('t_CHOOSEAGERANGE'),
        tMsgNumNightsSelected: this.$t('t_FORMINFOXNIGHTSSELECTED'),
        tMsgSelectCheckInDate: this.$t('t_FORMINPUTSELECTCHECKIN'),
        tMsgSelectCheckOutDate: this.$t('t_FORMINPUTSELECTCHECKOUT'),
        tNextButton: this.$t('t_FORMINPUTNEXT'),
        tSearchResultsAria: this.$t('t_SEARCHRESULTSARIA'),
        tSubmitLetsGo: this.$t('t_FORMINPUTLETSGO'),
      };
    },

    isGroupSearch() {
      return this.getSearchNumberOfGuests.guests >= groups.minGuests - 1;
    },
  },

  created() {
    let actualNumberOfGuests = search.defaultGuests;
    let actualNumberOfNights = search.initialNights;
    let actualNumberOfAddDays = search.defaultNights;

    // @TODO: STATICPWA-919 Remove after experiment
    const web_defaultDates = this.$optimizely.isFeatureEnabled('web_defaultDates');
    if (web_defaultDates) {
      actualNumberOfGuests =
        this.$optimizely.getFeatureVariableInteger('web_defaultDates', 'numberofguests') || actualNumberOfGuests;
      actualNumberOfNights =
        this.$optimizely.getFeatureVariableInteger('web_defaultDates', 'numberofnights') || actualNumberOfNights;
      actualNumberOfAddDays =
        this.$optimizely.getFeatureVariableString('web_defaultDates', 'numberofadddays') || actualNumberOfAddDays;

      // If it's set to auto, then we need to figure out if it's Friday, Saturday or Sunday, and switch to Monday
      if (actualNumberOfAddDays === 'auto') {
        const experimentActualDayOfTheWeek = new Date();
        const experimentDayOfTheWeek = experimentActualDayOfTheWeek.getDay();

        actualNumberOfAddDays = 1; // Default day in advance, if not one Friday, Saturday or Sunday
        // Thursday should also be considered on this, since the actual day is Thursday, checkin date will be plus one (Friday)
        if ([0, 4, 5, 6].indexOf(experimentDayOfTheWeek) >= 0) {
          switch (experimentDayOfTheWeek) {
            case 0:
              actualNumberOfAddDays = 1; // Sunday, goes to Monday
              break;
            case 4:
              actualNumberOfAddDays = 4; // Thursday, goes to Monday
              break;
            case 5:
              actualNumberOfAddDays = 3; // Friday, goes to Monday
              break;
            case 6:
              actualNumberOfAddDays = 2; // Saturday, goes to Monday
              break;
          }
        }
      }
    }

    // @TODO: STATICPWA-1082 Remove after experiment
    const web_defaultGuests = this.$optimizely.isFeatureEnabled('web_defaultGuests');
    if (web_defaultGuests) {
      actualNumberOfGuests =
        this.$optimizely.getFeatureVariableInteger('web_defaultGuests', 'number_of_guests') || actualNumberOfGuests;
    }

    if (!this.headerExperimentVars['empty-dates']) {
      const initialCheckIn = actualNumberOfAddDays ? checkinDate(null, actualNumberOfAddDays) : checkinDate();

      this.setCheckInDate({
        year: initialCheckIn.getFullYear(),
        month: initialCheckIn.getMonth() + 1,
        day: initialCheckIn.getDate(),
      });
      const initialCheckOut = new Date(
        initialCheckIn.getFullYear(),
        initialCheckIn.getMonth(),
        initialCheckIn.getDate()
      );
      initialCheckOut.setDate(initialCheckOut.getDate() + actualNumberOfNights);
      this.setCheckOutDate({
        year: initialCheckOut.getFullYear(),
        month: initialCheckOut.getMonth() + 1,
        day: initialCheckOut.getDate(),
      });
    }

    this.setSearchNumberOfGuests({
      guests: actualNumberOfGuests,
    });

    this.setSearchLocation(this.getDestinationObject);
    this.searchDefaultLocation = this.getDestinationObject;
  },

  mounted() {
    this.getTracking();
  },

  methods: {
    ...mapActions({
      setSearchLocation: 'searchForm/setSearchLocation',
      setSearchNumberOfGuests: 'searchForm/setSearchNumberOfGuests',
      setSearchGroupType: 'searchForm/setSearchGroupType',
      setSearchGroupAge: 'searchForm/setSearchGroupAge',
      setSearchActivated: 'searchForm/setSearchActivated',
      setSearchPhrase: 'searchForm/setSearchPhrase',
    }),

    async getTracking() {
      this.tracking = await this.$tracking.SearchFormComponent();
    },

    handleSearchFormReady() {
      /* POPUP THE DATE-PICKER */
      if (
        this.headerExperimentVars['datepicker-auto-popup'] &&
        !this.isSmallOrMediumScreen &&
        (this.isCityPage || this.isDistrictPage)
      ) {
        const searchForm = this.$refs.searchFormInline;
        searchForm.setCheckInHighlight();
        searchForm.handleFocusedDates();
      }
    },

    handleDestinationFieldClicked() {
      if (this.isBelowMediumScreen) {
        this.wizardActiveStep = 'destination';
        this.wizardIsOpen = this.isBelowMediumScreen;
      }
      if (!this.getIsSearchActivated) {
        this.tracking?.onSearchActivated('destination', 'search-form');
        this.setSearchActivated(true);
      }
    },

    handleDatesFieldClicked() {
      if (this.isBelowMediumScreen) {
        this.wizardActiveStep = 'dates';
        this.wizardIsOpen = this.isBelowMediumScreen;
      }
      if (!this.getIsSearchActivated) {
        this.tracking?.onSearchActivated('check-in date', 'search-form');
        this.setSearchActivated(true);
      }
    },

    handleGuestsFieldClicked() {
      if (this.isBelowMediumScreen) {
        this.wizardActiveStep = 'guests';
        this.wizardIsOpen = this.isBelowMediumScreen;
      }
      if (!this.getIsSearchActivated) {
        this.tracking?.onSearchActivated('guests', 'search-form');
        this.setSearchActivated(true);
      }
    },

    async handleDestinationSearch(search) {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(async () => {
        const autoCompleteServices = await this.$api.getHwRwd().getServices('autocomplete');
        await autoCompleteServices
          .search(search.term)
          .then((results) => {
            this.searchResults = this.handleSearchResults(results);
          })
          .catch((error) => {
            console.error(error);
          });
      }, this.debounce);
    },

    handleSearchResults(response) {
      if (response?.length === 1 && response[0]?.type === 'no-results') {
        this.searchResults = [];
        return;
      }

      return response.splice(0, this.maxLocations).reduce((prevState, result) => {
        let categoryIndex = prevState.findIndex((c) => c.label === result.category);
        if (categoryIndex < 0) {
          prevState.push({ label: result.category, items: [] });
          categoryIndex = prevState.length - 1;
        }

        const [label, ...labelSmall] = result.label.split(',');

        prevState[categoryIndex].items.push({
          id: result.id,
          type: result.type,
          city: result?.city,
          cityId: result?.cityId,
          label: label.trim(),
          labelSmall: labelSmall.join(',').trim(),
          labelSlug: label
            .trim()
            .toLowerCase()
            .replace(/[\W_]+/g, '-'),
          value: String(result.id),
          icon: this.getSearchResultIcon(result.type),
        });

        return prevState;
      }, []);
    },

    getSearchResultIcon(type) {
      switch (type) {
        case 'country':
          return 'globe';
        case 'city':
          return 'city';
        case 'citydistricts':
          return 'landmark';
        case 'property':
          return 'hostel';
        default:
          return 'location-pin';
      }
    },

    handleDestinationItemSelected(item) {
      this.setSearchLocation(item);
      this.setSearchPhrase(`${item?.label}${item.labelSmall ? ', ' + item.labelSmall : ''}`);
      this.searchDefaultLocation = item;
    },

    handleDatesSelected(dates) {
      this.setCheckInDate(dates?.start);
      this.setCheckOutDate(dates?.end);
    },

    handleGuestsSelected(guests) {
      this.setSearchNumberOfGuests({ guests: guests?.value ? guests.value : guests });
    },

    handleGuestsGroupTypeChanged(groupType) {
      const groupTypeObject = this.getGuestsGroupTypes.find((gtype) => gtype.value === groupType);
      this.setSearchGroupType(groupTypeObject);
    },

    handleGuestsGroupAgesChanged(groupAges) {
      this.setSearchGroupAge(groupAges);
    },

    async handleCurrentLocation(position) {
      const lang = this.getCurrentLanguage.tag || DEFAULT_LANGUAGE.tag;

      try {
        const cityServices = await this.$api.getHwApi().getServices('cities');
        const city = await cityServices.getByCoordinates(lang, position.latitude || 0, position.longitude || 0);

        if (city?.data?.id) {
          this.handleDestinationItemSelected({
            id: city.data.id,
            type: 'city',
            label: city.data.name,
            labelSmall: city.data.country,
            value: String(city.data.id),
          });
        }
      } catch (err) {
        console.error(`Unable to get user's current position: "${err.message}"`);
      }
    },

    async submit(params) {
      let redirectUrl = null;
      let trackingData = {};

      const langUrl = this.getCurrentLanguage.tag || DEFAULT_LANGUAGE.tag;
      const userCurrency = this.getCurrentCurrency.code;
      const searchLocation = this.getSearchLocation;

      const propertyTypeObj =
        PROPERTY_TYPES.find((pt) => this.$t(pt.slug) === this.$store.state.route.data.propertyType) ||
        PROPERTY_TYPE_DEFAULT;

      this.setCheckInDate(params.checkin);
      this.setCheckOutDate(params.checkout);

      let extraParams = {
        from: this.getCheckInDate(),
        to: this.getCheckOutDate(),
        guests: this.getSearchNumberOfGuests.guests,
      };

      const dateLeadTime = leadTime(extraParams.from);

      trackingData.arrival_date = extraParams.from;
      trackingData.departure_date = extraParams.to;
      trackingData.number_nights = numberOfNights(extraParams.from, extraParams.to);
      trackingData.number_guests = extraParams.guests;
      trackingData.search_keywords = searchLocation?.labelSlug || TRACKING_EMPTY;
      trackingData.property_type = this.$store.state.route.data.propertyType;
      trackingData.lead_time = dateLeadTime > 0 ? dateLeadTime : 'zero';
      trackingData.category = propertyTypeObj.key;

      switch (searchLocation.type) {
        case 'continent':
          const continentData = {
            propertyTypeSlug: this.$t(propertyTypeObj.slug),
            urlFriendlyContinent: searchLocation.labelSlug,
          };
          trackingData.destination_continent = continentData?.urlFriendlyContinent;

          redirectUrl = this.$url.getContinentPageUrl(continentData, {});
          break;
        case 'country':
          const countriesServices = await this.$api.getSpApi().getServices('countries');
          const countryResponse = await countriesServices.getCountryById(
            langUrl,
            userCurrency,
            propertyTypeObj.key,
            searchLocation.id
          );

          const countryData = {
            propertyTypeSlug: this.$t(propertyTypeObj.slug),
            urlFriendlyContinent: countryResponse.urlFriendlyContinent,
            urlFriendlyCountry: countryResponse.urlFriendlyName,
          };

          trackingData.destination_country = countryData?.urlFriendlyCountry;
          trackingData.destination_continent = countryData?.urlFriendlyContinent;

          redirectUrl = this.$url.getCountryPageUrl(countryData, {});
          break;
        case 'state':
          const areasServices = await this.$api.getSpApi().getServices('areas');
          const areasResponse = await areasServices.getAreaById(
            langUrl,
            userCurrency,
            propertyTypeObj.key,
            searchLocation.id
          );

          const areaCountryData = {
            propertyTypeSlug: this.$t(propertyTypeObj.slug),
            urlFriendlyContinent: areasResponse.urlFriendlyContinent,
            urlFriendlyCountry: areasResponse.urlFriendlyCountry,
          };

          const areaData = {
            urlFriendlyName: areasResponse.urlFriendlyName,
          };

          trackingData.destination_area = areaData?.urlFriendlyName;
          trackingData.destination_country = areaCountryData?.urlFriendlyCountry;
          trackingData.destination_continent = areaCountryData?.urlFriendlyContinent;

          redirectUrl = this.$url.getAreasUrl(areaCountryData, areaData, null);
          break;
        case 'region':
          const regionsServices = await this.$api.getSpApi().getServices('regions');
          const regionResponse = await regionsServices.getRegionById(
            langUrl,
            userCurrency,
            propertyTypeObj.key,
            searchLocation.id
          );

          const regionCountryData = {
            propertyTypeSlug: this.$t(propertyTypeObj.slug),
            urlFriendlyContinent: regionResponse.urlFriendlyContinent,
            urlFriendlyCountry: regionResponse.urlFriendlyCountry,
          };

          const regionData = {
            urlFriendlyName: regionResponse.urlFriendlyName,
          };

          trackingData.destination_region = regionData?.urlFriendlyName;
          trackingData.destination_country = regionCountryData?.urlFriendlyCountry;
          trackingData.destination_continent = regionCountryData?.urlFriendlyContinent;

          redirectUrl = this.$url.getRegionsUrl(regionCountryData, regionData, null);
          break;
        case 'city':
          const cityLocationData = {
            id: searchLocation.id,
            cityName: searchLocation.label,
            countryName: searchLocation.labelSmall,
            urlFriendlyName: searchLocation.label,
            urlFriendlyCountry: searchLocation.labelSmall,
          };

          trackingData.destination_city = cityLocationData?.urlFriendlyName;
          trackingData.destination_city_id = cityLocationData?.id?.toString();
          trackingData.destination_country = cityLocationData?.urlFriendlyCountry;
          trackingData.auto_sort_order_applied = 'sfab';
          trackingData.page_sort_order_applied = 'sfab';
          trackingData.listing_type = 'list';

          redirectUrl = this.$url.getCityDynamicFabUrl(cityLocationData, extraParams);
          break;
        case 'citydistricts':
          extraParams = {
            ...extraParams,
            ...{ district: searchLocation.id },
          };

          const cityDistrictParts = searchLocation.labelSmall.replace(/ /g, '').split(',');

          const cityDistrictData = {
            id: searchLocation.cityId,
            cityName: cityDistrictParts[0],
            countryName: cityDistrictParts[1],
            urlFriendlyName: cityDistrictParts[0],
            urlFriendlyCountry: cityDistrictParts[1],
          };

          trackingData.destination_district = searchLocation.label;
          trackingData.destination_city = cityDistrictData?.urlFriendlyName;
          trackingData.destination_city_id = cityDistrictData?.id?.toString();
          trackingData.destination_country = cityDistrictData?.urlFriendlyCountry;
          trackingData.auto_sort_order_applied = 'sfab';
          trackingData.page_sort_order_applied = 'sfab';
          trackingData.listing_type = 'list';

          redirectUrl = this.$url.getCityDynamicFabUrl(cityDistrictData, extraParams);
          break;
        case 'property':
          const propertyLocationData = {
            urlFriendlyName: searchLocation.city.replace(/[\W_]+/g, '-'),
          };
          const propertyData = {
            id: searchLocation.id,
            urlFriendlyName: searchLocation.label.replace(/[\W_]+/g, '-'),
          };

          trackingData.property_name = propertyData?.urlFriendlyName;
          trackingData.destination_city = propertyLocationData?.urlFriendlyName;
          trackingData.product_id = propertyData?.id?.toString();
          trackingData.sku = propertyData?.id?.toString();

          redirectUrl = this.$url.getPwaPropertyDynamicUrl(propertyLocationData, propertyData, extraParams);
          break;
      }

      if (this.isGroupSearch) {
        const groupType = this.getSearchGroupType;
        const groupAges = this.getSearchGroupAge;

        if (groupType.value && groupType.code) {
          redirectUrl += `&groupType=${groupType.value}`;
          trackingData.group_type = groupType.code;
        }

        if (groupAges.length > 0) {
          trackingData.group_age_range = groupAges.map((groupAge) => groups.ageRanges[groupAge].label).join(',');

          groupAges.forEach((groupAge) => {
            redirectUrl += `&groupAgeRange=${groupAge}`;
          });
        }
      }

      if (this.isPropertyPage) {
        redirectUrl += `&origin=microsite`;
      }

      if (redirectUrl) {
        this.tracking?.onSearchSubmitted(trackingData);

        setTimeout(() => {
          setHref(redirectUrl);
        }, 100);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-form-wrapper {
  width: 100%;

  .search-form-inline-container {
    display: none;
    margin: 0 auto;
  }

  @include tablet-large {
    .search-form-stacked-container,
    .search-form-wizard-container {
      display: none;
    }

    .search-form-inline-container {
      display: block;
      max-width: wds-rem(1024px);
    }
  }
}
</style>
