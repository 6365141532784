<template>
  <component v-if="locationHasProperties" :is="tag" class="popular-properties-in-location-section">
    <h3 class="popular-properties-heading" v-html="popularInLocationText" />
    <div class="popular-properties-locations-wrapper">
      <div class="popular-properties-locations">
        <component
          :is="location.id === activeLocation.id ? 'wds-pill-active' : 'wds-pill-select'"
          v-for="location in locationList"
          :key="location.id"
          :text="location.name"
          class="popular-properties-location"
          @click.prevent="setActiveLocation(location)"
        />
      </div>
    </div>
    <div class="popular-properties-top-rated" v-html="topRatedPropertiesInLocationText"></div>
    <div class="popular-properties-list-wrapper">
      <div
        v-for="location in locationList"
        :key="`props${location.id}`"
        v-show="location.id === activeLocation.id"
        class="popular-properties-card-list"
      >
        <wds-property-card
          v-for="property in getPopularPropertiesFromLocation(location)"
          :key="property.id"
          v-bind="getPropertyDetailsForCard(property)"
          @sustainability-pin-click="handleSustainabilityPinClick(property.sustainability)"
        />
      </div>
    </div>
    <wds-button-link
      :href="linkToActiveCityPage"
      :text="viewAllPropertiesInLocationText"
      :title="viewAllPropertiesInLocationText"
      :arial-label="viewAllPropertiesInLocationText"
      rel="noopener"
      icon-end="chevron-right"
      class="popular-properties-view-all"
    />
  </component>
</template>

<script>
import { WdsPillSelect, WdsPillActive, WdsPropertyCard, WdsButtonLink } from '@wds/components';
import { findPropertyTypeByKey } from '~/lib/propertyTypes';
import { UrlBuilder } from '~/lib/url';
import ratingMixin from '~/mixins/Rating';
import { mapActions } from 'vuex';

export default {
  name: 'PopularPropertiesInLocation',
  mixins: [ratingMixin],
  components: {
    WdsPillActive,
    WdsPillSelect,
    WdsPropertyCard,
    WdsButtonLink,
  },
  inject: {
    trackingInfoFromPage: {
      default: () => ({}),
    },
  },
  props: {
    tag: {
      type: String,
      required: false,
      default: 'div',
    },
    propertyTypeObj: {
      type: Object,
      required: true,
    },
    locationName: {
      type: String,
      required: true,
    },
    locationList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tracking: null,
      activeLocation: this.locationList[0] || null,
      sustainability_enabled: false,
    };
  },
  computed: {
    locationHasProperties() {
      return this.locationList.length > 0;
    },
    popularInLocationText() {
      return this.$t('t_POPULARINLOCATION', {
        LOCATION: `<span>${this.locationName}</span>`,
      });
    },
    topRatedPropertiesInLocationText() {
      return this.$t('t_TOPRATEDPROPERTIESINLOCATION', {
        PROPERTYTYPE: this.$t(this.propertyTypeObj.transcode).toLowerCase(),
        LOCATION: `<strong>${this.fullActiveLocationName}</strong>`,
      });
    },
    fullActiveLocationName() {
      const locationNames = [];

      if (this.activeLocation?.name) {
        locationNames.push(this.activeLocation.name);
      }

      if (this.activeLocation?.country) {
        locationNames.push(this.activeLocation.country);
      }

      return locationNames.join(', ');
    },
    linkToActiveCityPage() {
      return this.$url.getCityUrl({
        propertyTypeSlug: this.propertyTypeObj.slug,
        urlFriendlyContinent: this.activeLocation?.urlFriendlyContinent,
        urlFriendlyCountry: this.activeLocation?.urlFriendlyCountry,
        urlFriendlyCity: this.activeLocation?.urlFriendlyName,
      });
    },
    viewAllPropertiesInLocationText() {
      return this.$t('t_VIEWALLPROPERTIESINLOCATION', {
        PROPERTYTYPE: this.$t(this.propertyTypeObj.transcode).toLowerCase(),
        LOCATION: this.activeLocation?.name,
      });
    },
  },
  mounted() {
    this.getTracking();
    this.sustainability_enabled = this.$optimizely.isFeatureEnabled('web_sustainabilityBadges');
  },
  methods: {
    ...mapActions({
      setSustainabilityDetails: 'session/setSustainabilityDetails',
    }),
    async getTracking() {
      this.tracking = await this.$tracking.PropertyCardComponent();
    },
    setActiveLocation(location) {
      this.activeLocation = location;
    },
    getPopularPropertiesFromLocation(location) {
      return location.properties.slice(0, 4);
    },
    getPropertyDetailsForCard(property) {
      const propertyType = findPropertyTypeByKey(property?.type);
      const propertyImage = property?.image?.medium || null;
      const hasPrivateRooms = property.privateMinPrice.value > 0;
      const hasDormRooms = property.sharedMinPrice.value > 0;
      const propSlides = propertyImage ? [new UrlBuilder(propertyImage).addProtocol()] : [];
      const hrefLink = this.$url.getPropertyUrl(
        {
          propertyTypeSlug: this.$t(propertyType.slug),
        },
        {
          id: property.id,
          urlFriendlyName: property.urlFriendlyName,
        }
      );

      return {
        type: this.$t(propertyType?.transcode_s),
        name: property.name,
        href: hrefLink,
        gallery: {
          slides: propSlides,
          alt: property.name,
          title: property.name,
        },
        rating: {
          score: property.avgRating,
          totalReviews: property?.numberReviews,
          translations: this.getRatingScoreTranslations,
        },
        distance: property?.cityCenterDistance
          ? this.$t('t_FROMCITYCENTER', { DISTANCE: property.cityCenterDistance.toString() })
          : null,
        minPrivatePrice: {
          curPrice: hasPrivateRooms
            ? this.$currency.format(property.privateMinPrice.value, property.privateMinPrice.currency)
            : null,
        },
        minDormPrice: {
          curPrice: hasDormRooms
            ? this.$currency.format(property.sharedMinPrice.value, property.sharedMinPrice.currency)
            : null,
        },
        translations: {
          tPrivatesFrom: this.$t('t_PRIVATESFROM'),
          tNoPrivatesAvailable: this.$t('t_NOPRIVATESAVAILABLE'),
          tDormsFrom: this.$t('t_DORMSFROM'),
          tNoDormsAvailable: this.$t('t_NODORMSAVAILABLE'),
          tNoAvailability: this.$t('t_NOAVAILABILITY'),
        },
        sustainabilityLevel:
          this.sustainability_enabled && property?.sustainability?.tier && property.sustainability?.tier !== '0'
            ? `TIER_${property?.sustainability?.tier}`
            : null,
      };
    },
    handleSustainabilityPinClick(propertySustainabilityInfo) {
      this.setSustainabilityDetails(propertySustainabilityInfo);

      const trackingInfo = {
        gtm: {
          parameter1: `SUSTAINABILITY_TIER_${propertySustainabilityInfo?.tier}`,
          parameter2: this.trackingInfoFromPage.gtmPageName,
        },
      };

      this.tracking?.onSustainabilityIconClick(trackingInfo.gtm, trackingInfo.segment);
    },
  },
};
</script>

<style lang="scss" scoped>
.popular-properties-in-location-section {
  display: flex;
  flex-direction: column;

  .popular-properties-heading {
    @include title-2-bld;
    color: $wds-color-ink-darker;
    padding: 0 $wds-spacing-m;
    margin-bottom: $wds-spacing-m;

    ::v-deep span {
      color: $wds-color-purple;
    }
  }

  .popular-properties-locations-wrapper {
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: $wds-spacing-l;
    width: 100%;
    margin-bottom: $wds-spacing-m;

    .popular-properties-locations {
      display: flex;
      gap: $wds-spacing-s;
      overflow-x: auto;
      padding: $wds-spacing-m;

      .popular-properties-location {
        white-space: nowrap;
      }
    }
  }

  .popular-properties-top-rated {
    padding: 0 $wds-spacing-m;
    margin-bottom: $wds-spacing-m;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .popular-properties-list-wrapper {
    overflow: hidden;
    border-radius: $wds-spacing-l;
    width: 100%;

    .popular-properties-card-list {
      display: inline-flex;
      gap: $wds-spacing-m;
      overflow-x: auto;
      padding: $wds-spacing-m;
      width: 100%;

      > * {
        flex: 0 0 wds-rem(320px);
        height: auto;
      }
    }
  }

  .popular-properties-view-all {
    padding: 0 $wds-spacing-m;
    cursor: pointer;
    align-self: flex-end;
  }

  @include desktop {
    align-items: center;
    padding: 0 wds-rem(40px);

    .popular-properties-heading {
      @include title-1-bld;
      margin-bottom: $wds-spacing-l;
    }

    .popular-properties-locations-wrapper {
      margin-bottom: $wds-spacing-m;
    }

    .popular-properties-top-rated {
      margin-bottom: $wds-spacing-xl;
    }

    .popular-properties-list-wrapper .popular-properties-card-list {
      justify-content: flex-start;
      overflow-x: auto;
      padding: $wds-spacing-m;
      margin: 0;
      width: 100%;

      > * {
        flex-grow: 1;
        min-width: wds-rem(320px);
        max-width: 25%;
      }
    }
  }
}
</style>
