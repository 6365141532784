<template functional>
  <a :href="props.pillLink" v-bind="data.attrs" class="pill">
    {{ props.pillText }}
  </a>
</template>

<script>
export default {
  name: 'Pill',
  inheritAttrs: false,
  props: {
    pillText: {
      type: String,
      required: true,
    },
    pillLink: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.pill {
  @include body-1-bld;

  display: block;
  padding: wds-rem(12px) $wds-spacing-l;
  background-color: $wds-color-purple;
  color: $wds-color-white;
  border-radius: $wds-spacing-l;
  text-decoration: none;
  outline: none;

  &:hover {
    box-shadow: $wds-shadow-light-l;
  }

  &[disabled] {
    opacity: $wds-opacity-medium;
  }

  &:focus-visible {
    box-shadow: 0 0 0 0.125rem $wds-color-white, 0 0 0 0.25rem $wds-color-ink-darker;
  }
}
</style>
